import React from 'react'
import Top from 'components/pages/Top'

const App: React.FC = () => {
  return (
    <>
      <Top />
    </>
  )
}

export default App
